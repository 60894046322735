export const setupMapping = {
  1: 'Previous Client Set-Up Screens',
  2: 'Redesigned Client Set-Up Screens',
  3: 'Previous Freelancer Set-Up Screens',
  4: 'Redesigned Freelancer Set-Up Screens',
  5: 'Success Modal Screens'
}

export const freelancerMapping = {
  1: 'Previous Freelancer Ongoing Use Screens',
  2: 'Redesigned Freelancer Ongoing Use Screens',
  3: 'Redesigned Freelancer Ongoing Use Screens'
}
